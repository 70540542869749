import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"

import {
  Page,
  MajorColumn,
  MinorColumn,
  FlexWrapMobile
} from "../../components/content-elements"
import GalleryListing from "../../components/gallery_listing"

const DynamicPageTemplate = ({ data, location, children }) => {
  const { page, galleries } = data

  return (
    <Layout location={location}>
      <FlexWrapMobile>
        <MinorColumn>
          <GalleryListing data={galleries} columns={2} />

        </MinorColumn>
        <MajorColumn>
          <Page page={page} seo={true} />

          {children}

        </MajorColumn>
      </FlexWrapMobile>

    </Layout>
  )
}


export const dynamicPageQuery = graphql`

    fragment GeneralDynamciPageFields on MarkdownRemark {
        id
        excerpt(pruneLength: 160)
        htmlAst
        html
        fields {
            slug
        }
        frontmatter {
            heading
            subheading
            tags
            image
            date
            menu_label
        }
    }

    fragment DynamicPageFragment on Query {
        page: markdownRemark(id: {eq: $id}) {
            ...GeneralDynamciPageFields
        }
        marketing: markdownRemark(id: {eq: $id}) {
            ...SEOFields
            ...MarketingFields
        }
    }

    query dynnamicPageById($id: String!) {
        ...DynamicPageFragment
        ...GalleryListFragment
        ...BlogListFragment
    }
`

export default DynamicPageTemplate
